import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { NzTableQueryParams } from 'ng-zorro-antd'
import { Router } from '@angular/router'

interface TableHeader {
  title: string
  field: string
  visible: boolean
  sortable: boolean
  searchable: boolean
  drilldown: string
  type: string
}

interface TableData {
  headers: TableHeader[]
  rows: Record<string, any>[]
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  unsorted = (a, b) => a

  @Input('pageSize')
  pageSize = 15

  @Input('pageIndex')
  pageIndex = 1

  @Input('total')
  total = 1

  @Input('loading')
  loading = false

  @Input('data')
  data: TableData

  @Input('getData')
  getData = (s = 1) => {
  }

  @Output() onChanged = new EventEmitter<any>()

  constructor(private http: HttpClient, private router: Router,) {
  }

  isVisible(key) {
    return this.data.headers.find(h => h.field === key)?.visible
  }

  getDrilldownLink(key) {
    return this.data.headers.find(h => h.field === key)?.drilldown
  }

  getFieldType(key) {
    const fieldHeader = this.data.headers.find(h => h.field === key)
    if (fieldHeader.drilldown) {
      return 'drilldown'
    }
    return fieldHeader.type
  }

  navigateDrilldown(key, row) {
    const linkTemplate = this.getDrilldownLink(key)
    const fullLink = linkTemplate.replace('{{id}}', row.id)
    this.router.navigate([fullLink])
  }

  onQueryParamsChange(params: NzTableQueryParams) {
    console.log('params', params)
    const { pageIndex } = params
    this.onChanged.emit(pageIndex)
  }

}

import { Component } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, Routes } from '@angular/router'
import { distinctUntilChanged, filter } from 'rxjs/operators'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {

  childrenRoutes: Routes
  isOnDashboardHomePage = false

  constructor(private route: ActivatedRoute, private router: Router) {
    this.childrenRoutes = this.route.routeConfig.children
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        distinctUntilChanged()
      ).subscribe(e => {
        this.isOnDashboardHomePage = this.router.url === '/dashboard'
      })
  }

}

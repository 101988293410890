import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from '../pages/login/login.component'
import { DashboardComponent } from '../pages/dashboard/dashboard.component'
import { AuthGuard } from './auth/auth.guard'
import { ChallengesComponent } from '../pages/dashboard/challenges/challenges.component'
import { UsersComponent } from '../pages/dashboard/users/users.component'
import { UserComponent } from '../pages/dashboard/users/user/user.component'
import { SendingComponent } from '../pages/dashboard/sending/sending.component'


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    data: {
      title: 'Dashboard',
    },
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'users',
        component: UsersComponent,
        data: {
          title: 'Users',
        },
      },
      {
        path: 'user/:id',
        component: UserComponent,
        data: {
          title: 'Users',
          hideInMenu: true,
        },
      },
      {
        path: 'challenges',
        component: ChallengesComponent,
        data: {
          title: 'Challenges',
        },
      },
      {
        path: 'sending',
        component: SendingComponent,
        data: {
          title: 'Sending',
        },
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

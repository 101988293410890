<div>
  <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
    <input [(ngModel)]="search" type="text" nz-input placeholder="Введите email"/>
  </nz-input-group>
  <ng-template #suffixButton>
    <button nz-button nzType="primary" nzSize="large" nzSearch (click)="getData()">Поиск</button>
  </ng-template>
  <app-table
    [data]="data"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [loading]="loading"
    [total]="total"
    (onChanged)="getData($event)"
  >
  </app-table>
</div>

<div class="login-page">
  <nz-card class="card">

    <form nz-form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
      <nz-form-item>
        <nz-input-group nzPrefixIcon="user">
          <input type="text" nz-input formControlName="login" placeholder="login"/>
        </nz-input-group>
      </nz-form-item>

      <nz-form-item>
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input formControlName="password" placeholder="Password"/>
        </nz-input-group>
      </nz-form-item>

      <button nzBlock nz-button nzType="primary">Log in</button>

    </form>

  </nz-card>
</div>

<nz-layout class="dashboard-layout">
  <nz-header>
    <div class="logo">logo</div>
    <ul nz-menu nzTheme="dark" nzMode="horizontal">
      <ng-container *ngFor="let route of childrenRoutes">
        <li *ngIf="!route.data.hideInMenu" nz-menu-item>
          <a [routerLink]="['/dashboard/', route.path]" routerLinkActive="active">{{route.data.title}}</a>
        </li>
      </ng-container>
    </ul>
  </nz-header>
  <nz-content>
    <nz-breadcrumb [nzAutoGenerate]="true" [nzRouteLabel]="'title'"></nz-breadcrumb>
    <div class="inner-content">
      <div class="home-content" *ngIf="isOnDashboardHomePage">Home dashboard page</div>
      <router-outlet></router-outlet>
    </div>
  </nz-content>
  <nz-footer>2020</nz-footer>
</nz-layout>

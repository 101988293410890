import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { AuthService } from '../../core/auth/auth.service'
import { Router } from '@angular/router'
import { NzMessageService } from 'ng-zorro-antd'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messageService: NzMessageService,
  ) {
    this.loginForm = this.formBuilder.group({
      login: '',
      password: '',
    })
  }

  ngOnInit(): void {
    if (this.authService.checkStatus) {
      this.router.navigate(['dashboard/users'])
    }
  }

  async onSubmit(data) {
    this.loginForm.reset()
    const isLogged = await this.authService.login(data)
    if (!isLogged) {
      this.messageService.error('Wrong credentials')
      return
    }
    this.messageService.success('Successfully logged in')
    await this.router.navigate(['dashboard/users'])
  }

}

import { Injectable } from '@angular/core'
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { AuthService } from './auth/auth.service'

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        tap(data => console.log('d', data)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            console.log('401')
            this.authService.logout()
          }
          return throwError(error)
        })
      )
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpInterceptor,
  multi: true,
}

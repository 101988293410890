<div class="info">
  <div class="item">
    <b>Имя:</b> {{ user.name}}
  </div>
  <div class="item">
    <b>Telegram:</b> {{ user.telegram}}
  </div>
  <div class="item">
    <b>Телефон:</b> {{ user.phone}}
  </div>
  <div class="item">
    <b>Email:</b> {{ user.email}}
  </div>
  <div class="item">
    <b>Дата создания</b>: {{user?.createdAt | date:'medium':null: 'ru_RU'}}
  </div>
</div>

<h2>Транзакции</h2>

<nz-table #basicTable [nzData]="user?.transactions.rows" [nzScroll]="{ x: '1150px'}">
  <thead>
  <tr>
    <ng-container *ngFor="let data of user?.transactions.headers">
      <th [nzColumnKey]="data.key">
        {{data.title}}
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of basicTable.data">
    <td *ngFor="let col of row | keyvalue:unsorted">
      {{ col.value }}
    </td>
  </tr>
  </tbody>
</nz-table>

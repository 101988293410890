import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DashboardComponent } from './dashboard.component'
import { TableComponent } from '../../components/table/table.component'
import { AntModule } from '../../core/ant/ant.module'
import { AppRoutingModule } from '../../core/app-routing.module'
import { ChallengesComponent } from './challenges/challenges.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UsersComponent } from './users/users.component'
import { UserComponent } from './users/user/user.component'
import { SendingComponent } from './sending/sending.component'


@NgModule({
  declarations: [
    DashboardComponent,
    TableComponent,
    ChallengesComponent,
    UsersComponent,
    UserComponent,
    SendingComponent,
  ],
  imports: [
    CommonModule,
    AntModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    DashboardComponent,
    TableComponent,
  ],
})
export class DashboardModule { }

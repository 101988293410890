import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { NzMessageService } from 'ng-zorro-antd'

interface Credentials {
  login: string
  password: string
}

interface LoginDto {
  result: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router, private messageService: NzMessageService) {
  }

  checkStatus(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true'
  }

  async login(body: Credentials): Promise<boolean> {
    try {
      const r = await this.http.post<LoginDto>('/api/auth/login', body, { withCredentials: true }).toPromise()
      if (r.result === 'ok') {
        localStorage.setItem('isLoggedIn', 'true')
        return true
      }
      localStorage.setItem('isLoggedIn', 'false')
      return false

    } catch (e) {
      localStorage.setItem('isLoggedIn', 'false')
      return false
    }
  }

  async logout() {
    this.messageService.error('Unauthorized')
    localStorage.setItem('isLoggedIn', 'false')
    await this.router.navigate(['/'])
  }
}

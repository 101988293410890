/* eslint-disable camelcase */
import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './core/app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './pages/login/login.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n'
import { registerLocaleData } from '@angular/common'
import { AntModule } from './core/ant/ant.module'
import { DashboardModule } from './pages/dashboard/dashboard.module'
import { ErrorInterceptorProvider } from './core/http.interceptor'
import localeRu from '@angular/common/locales/ru'

registerLocaleData(localeRu, 'ru')

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    AntModule,
    HttpClientModule,
    DashboardModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: ru_RU },
    ErrorInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { NzMessageService } from 'ng-zorro-antd'
import { FormBuilder } from '@angular/forms'

@Component({
  selector: 'app-sending',
  templateUrl: './sending.component.html',
  styleUrls: ['./sending.component.scss'],
})
export class SendingComponent implements OnInit {
  form

  data = {
    headers: null,
    rows: null,
  }
  loading = false
  total = 1
  pageSize = 15
  pageIndex = 1

  constructor(
    private http: HttpClient,
    private messageService: NzMessageService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      email: '',
      name: '',
    })
  }

  getData(page = 1, pageSize = this.pageSize) {
    this.loading = true

    const params = {
      page: String(page),
      size: String(pageSize),
    }

    this.http.get<any>('/api/handmail/list', { params })
      .subscribe(r => {
        if (!this.data.headers) {
          this.data.headers = r.headers
        }
        this.data.rows = r.rows
        this.total = r.total
        this.loading = false
      })
  }

  ngOnInit(): void {
    this.getData()
  }

  async sendEmail(data, courseId :number, isGift: boolean = false) {
    this.http.post('/api/handmail/send', { ...data, courseId, isGift }).subscribe(
      r => {
        this.messageService.success('Сообщение отправлено')
        this.getData()
      },
      e => {
        this.messageService.error('Произошла ошибка')
      }
    )
  }

}

<nz-table
  #basicTable
  [nzData]="data.rows"
  [nzFrontPagination]="false"
  [nzLoading]="loading"
  [nzTotal]="total"
  [nzPageSize]="pageSize"
  [nzPageIndex]="pageIndex"
  [nzScroll]="{ x: '1150px'}"
  (nzQueryParams)="onQueryParamsChange($event)">
  <thead>
  <tr>
    <ng-container *ngFor="let header of data.headers">
      <th *ngIf="header.visible"
          [nzSortFn]="header.sortable"
          [nzColumnKey]="header.field"
      >
        {{header.title}}
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of basicTable.data">
    <ng-container *ngFor="let col of row | keyvalue:unsorted">
      <td *ngIf="isVisible(col.key)" [ngSwitch]="getFieldType(col.key)">
        <a *ngSwitchCase="'drilldown'" (click)="navigateDrilldown(col.key, row)">
          {{col.value}}
        </a>
        <p *ngSwitchCase="'date'">{{ col.value | date:'medium':null: 'ru_RU'}}</p>
        <ng-template ngSwitchDefault>
          {{col.value}}
        </ng-template>
      </td>
    </ng-container>
  </tr>
  </tbody>
</nz-table>

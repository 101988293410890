import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'


interface TableHeader {
  title: string
  field: string
  visible: boolean
  sortable: boolean
  searchable: boolean
  drilldown: string
}

interface TableData {
  headers: TableHeader[]
  rows: Record<string, any>[]
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  search
  data: TableData = {
    headers: null,
    rows: null,
  }
  loading = false
  total = 1
  pageSize = 15
  pageIndex = 1

  constructor(private http: HttpClient) {
  }

  getData(page = 1, pageSize = this.pageSize, searchString = this.search) {
    this.loading = true

    const params = {
      page: String(page),
      size: String(pageSize),
      email: searchString,
    }

    this.http.get<any>('/api/dashboard/users', { params })
      .subscribe(r => {
        if (!this.data.headers) {
          this.data.headers = r.headers
        }
        this.data.rows = r.rows
        this.total = r.total
        this.loading = false
      })
  }

  ngOnInit(): void {
    this.getData()
  }

}

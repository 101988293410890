import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  NzBreadCrumbModule,
  NzButtonModule,
  NzCardModule, NzDescriptionsModule, NzDividerModule, NzDropDownModule,
  NzFormModule, NzIconModule,
  NzInputModule,
  NzLayoutModule,
  NzMenuModule,
  NzMessageModule, NzTableModule,
} from 'ng-zorro-antd'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzCardModule,
    NzLayoutModule,
    NzMenuModule,
    NzBreadCrumbModule,
    NzMessageModule,
    NzDividerModule,
    NzDropDownModule,
    NzTableModule,
    NzIconModule,
    NzDescriptionsModule,
  ],
})
export class AntModule {
}

<form nz-form [nzLayout]="'inline'" [formGroup]="form">
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your username!">
      <nz-input-group nzPrefixIcon="user">
        <input formControlName="email" nz-input placeholder="email"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your Password!">
      <nz-input-group nzPrefixIcon="lock">
        <input formControlName="name" nz-input placeholder="имя"/>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control>
      <button (click)="sendEmail(form.value, 8)" class="first-btn" nz-button nzType="primary">Отправить гайдбук</button>
      <button (click)="sendEmail(form.value, 18)" class="first-btn" nz-button nzType="primary">Отправить 18</button>
      <button (click)="sendEmail(form.value, 18, true)" nz-button nzType="primary">Отправить 18 подарок</button>
      <button (click)="sendEmail(form.value, 300)" nz-button nzType="primary">Отправить mind 300</button>
    </nz-form-control>
  </nz-form-item>
</form>

<app-table
  [data]="data"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [loading]="loading"
  [total]="total"
  (onChanged)="getData($event)"
>
</app-table>
